import Head from 'next/head';
import Error from 'next/error';

export default function NotFound(): JSX.Element {
  return (
    <>
      <Head>
        <title>404 - Page not found!</title>
      </Head>
      <Error statusCode={404} />
    </>
  );
}
